import { Box, Typography } from "@mui/material";
import useThemeMode from "../../../hooks/useTheme";
import useLocales from "../../../hooks/useLocales";
import { FC, useEffect } from "react";
import { addSeconds } from "date-fns";
import { WordleStateLocale, wordleStore } from "../../../features/wordle/WordleStore";
import { GameState, Languge } from "../../../utils/enums";
import { usePrevious } from "../../../hooks";
import { IValueLabel } from "../../../interfaces";
import { WordleTimerContent } from "../../../components";

type IProps = {
    isHeader?: boolean;
};

const WordleTimer: FC<IProps> = (props) => {
    const { isHeader = false } = props;
    const { theme }: any = useThemeMode();
    const { translate } = useLocales();
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();
    const previousLang: IValueLabel<Languge> = usePrevious(currentLang);

    useEffect(() => {
        const interval = setInterval(() => {
            if (wordleState[currentLang.value].gameState === GameState.PLAYING)
                wordleState.setGameTime(addSeconds(wordleState[currentLang.value].gameTime, 1), currentLang.value);
        }, 1000);

        return () => clearInterval(interval);
    }, [wordleState[currentLang.value].gameState, wordleState[currentLang.value].gameTime, currentLang.value]);

    useEffect(() => {
        if (previousLang && currentLang.value) wordleState.setGameTime(wordleState[previousLang.value].gameTime, previousLang.value);
    }, [currentLang.value]);

    return (
        <Box id="wordle-timer">
            {!isHeader && (
                <Typography id="wordle-timer-header" sx={{ color: theme.palette.wordleTimer.text }}>
                    {translate("timeSpentInGame")}
                </Typography>
            )}

            <WordleTimerContent />
        </Box>
    );
};

export default WordleTimer;
