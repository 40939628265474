import Header from "./Header";
import { ThemeMode } from "../../utils/enums";
import { useAppSelector } from "../../app/hooks";
import { FC, ReactNode, useEffect, useState } from "react";
import { Container, Divider, Hidden } from "@mui/material";
import { selectThemeMode } from "../../features/theme/ThemeSlice";

type IProps = {
    children: ReactNode;
};

const Layout: FC<IProps> = (props) => {
    const { children } = props;
    const mode: string = useAppSelector(selectThemeMode);
    const [pageHeight, setPageHeight] = useState<number>(0);

    useEffect(() => {
        const root: HTMLElement = document.getElementById("root") as HTMLElement;

        root.style.background = mode === ThemeMode.dark ? "#000000" : "#f4f5f6";
    }, [mode]);

    useEffect(() => {
        const handleResize = () => setPageHeight(window.innerHeight);

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Container id="main" className={mode} maxWidth={false} sx={{ height: `${pageHeight}px` }}>
            <Header />

            <Hidden smDown>
                <Divider orientation="horizontal" flexItem sx={{ marginTop: "6px" }} />
            </Hidden>

            <Container maxWidth="sm" id="main-content">
                {children}
            </Container>
        </Container>
    );
};

export default Layout;
