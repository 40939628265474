import { format } from "date-fns";
import { Box, Grid } from "@mui/material";
import StatisticDataItem from "./StatisticDataItem";
import { useLocales, useTheme } from "../../../../../../hooks";
import PredictionDistribution from "./PredictionDistribution";
import { useGetQuery } from "../../../../../../features/api/Api";
import { createHash, getCurrentDateForHeader, secondToDate } from "../../../../../../utils/methods";

const GameSummary = () => {
    const { translate, currentLang } = useLocales();
    const { theme }: any = useTheme();

    const { data: userStatues, isLoading }: any = useGetQuery({
        resource: "dailywords/user-status",
        params: { deviceToken: "6c39c61a-f5c7-4354-850b-91401593def9" },
        headers: {
            langCode: currentLang.value,
            currentDate: createHash(getCurrentDateForHeader),
        },
    });

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "35px" }}>
            {!isLoading && userStatues?.data && (
                <Grid container spacing={theme.spacing(2)} id="statistic-data">
                    <StatisticDataItem
                        title={translate("gamesPlayed")}
                        result={userStatues.data.playedGames}
                        isIncrease={userStatues.data.playedGamesRatio}
                        changeRate={userStatues.data.playedGamesPercent}
                        isLoading={isLoading}
                    />

                    <StatisticDataItem
                        title={translate("winningPercentage")}
                        result={`%${userStatues.data.wins}`}
                        isIncrease={userStatues.data.winsRatio}
                        changeRate={userStatues.data.winsPercent}
                        isLoading={isLoading}
                    />

                    <StatisticDataItem
                        title={translate("timePerGame")}
                        result={format(secondToDate(userStatues.data.spentTime), "mm:ss")}
                        isIncrease={userStatues.data.spentTimeRatio}
                        changeRate={userStatues.data.spentTimePercent}
                        isLoading={isLoading}
                    />
                </Grid>
            )}

            <Box>
                <PredictionDistribution />
            </Box>
        </Box>
    );
};

export default GameSummary;
