import { LinearProgress, SxProps, styled } from "@mui/material";
import { FC } from "react";

type IProps = {
    value: number;
    variant?: "determinate" | "indeterminate" | "buffer" | "query";
    sx?: SxProps;
};

/**
 * @param value Progress bar value
 * @param variant Progress bar visual type | default value "determinate"
 */
const LineerProgressBar: FC<IProps> = (props) => {
    const { value, variant, sx } = props;

    return <BundleLineerProgressBar value={value} variant={variant} sx={{ userSelect: "none", ...sx }} />;
};

const BundleLineerProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 26,
    borderRadius: 31.41,
    background: "none",
    "& .MuiLinearProgress-bar": {
        background: "#1B7A2E",
    },
}));

LineerProgressBar.defaultProps = {
    variant: "determinate",
};

export default LineerProgressBar;
