import { FC, Fragment } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useLocales, useTheme } from "../../../../../../hooks";
import { BundleLoadingLineer } from "../../../../../../components";
import { DecreaseIcon, IncreaseIcon } from "../../../../../../constants/icons";

type IProps = {
    title: string;
    result: string | number;
    isIncrease: boolean;
    changeRate: number;
    isLoading: boolean;
};

const StatisticDataItem: FC<IProps> = (props) => {
    const { title, result, isIncrease, changeRate, isLoading } = props;
    const { theme }: any = useTheme();
    const { translate } = useLocales();

    return (
        <Grid item xs={4}>
            <Box className="statistic-data-item" sx={{ background: theme.palette.statisticDialog.dataBackground }}>
                <Typography className="statistic-data-item-title" sx={{ color: theme.palette.statisticDialog.dataTitle }}>
                    {title}
                </Typography>

                <Typography className="statistic-data-item-result">
                    {isLoading ? <BundleLoadingLineer width="auto" sx={{ my: 1 }} /> : result}
                </Typography>

                <Box>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                        {isLoading ? (
                            <BundleLoadingLineer width="auto" sx={{ height: 11, flex: 1 }} />
                        ) : (
                            <Fragment>
                                {isIncrease ? <IncreaseIcon /> : <DecreaseIcon />}
                                <Typography className="statistic-data-item-change" sx={{ color: isIncrease ? "#31AC37" : "#E62C33" }}>
                                    {isNaN(changeRate) ? 0 : changeRate}%
                                </Typography>
                            </Fragment>
                        )}
                    </Box>

                    <Typography className="statistic-data-item-change-desc" sx={{ color: theme.palette.statisticDialog.dataTitle }}>
                        {isIncrease ? translate("increaseDesc") : translate("descraseDesc")}
                    </Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default StatisticDataItem;
