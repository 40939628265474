import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { Languge } from "./utils/enums";

i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
        debug: false,
        lng: localStorage.getItem("i18nextLng") || Languge.tr,
        supportedLngs: [Languge.tr, Languge.en],
        fallbackLng: Languge.tr,
        ns: ["main"],
        defaultNS: "main",
        load: "currentOnly",
        backend: {
            loadPath: "../locales/{{lng}}/{{ns}}.json",
        },

        interpolation: {
            escapeValue: false,
        },
    });

export default i18next;
