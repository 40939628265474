import { Box, Divider, Typography } from "@mui/material";
import useThemeMode from "../../../hooks/useTheme";
import useLocales from "../../../hooks/useLocales";
import { PhilipsIcon } from "../../../constants/brandIcons";

const Sponsor = () => {
    const { theme }: any = useThemeMode();
    const { translate } = useLocales();

    return (
        <Box id="sponsor">
            <PhilipsIcon width={70} height={15} />

            <Divider orientation="vertical" flexItem sx={{ background: "#B3B3B3" }} />

            <Typography id="sponsor-text" sx={{ color: theme.palette.sponsor.text }}>
                {translate("sponsorShip")}
            </Typography>
        </Box>
    );
};

export default Sponsor;
