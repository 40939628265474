import { create } from "zustand";
import { persist } from "zustand/middleware";

export type AuthState = {
    isAuthenticate: boolean;
    isPremium: boolean;
    useLogin: () => void;
    useLogout: () => void;
    changePremium: () => void;
};

export const authStore = create<AuthState>()(
    persist(
        (set, get) => ({
            isAuthenticate: false,
            isPremium: false,
            useLogin: () => {
                set(() => ({
                    isAuthenticate: true,
                }));
            },
            useLogout: () => {
                set(() => ({
                    isAuthenticate: false,
                    isPremium: false,
                }));
            },
            changePremium: () => {
                set(() => ({
                    isPremium: !get().isPremium,
                }));
            },
        }),
        { name: "auth" }
    )
);
