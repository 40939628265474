import { Fragment } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { useLocales, useTheme } from "../../../../../../hooks";
import { LoginIcon, LogoutIcon } from "../../../../../../constants/icons";
import { AuthState, authStore } from "../../../../../../features/authenticate/AuthStore";

const SettingLoginBlock = () => {
    const { theme }: any = useTheme();
    const { translate } = useLocales();
    const authState: AuthState = authStore();

    return authState.isAuthenticate ? (
        <Fragment>
            <Box>
                <Avatar src="/assets/images/profile.png" sx={{ width: 69, height: 69 }} />
            </Box>
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <Typography sx={{ fontSize: 26, fontWeight: 700 }}>Murat Yıldızaç</Typography>
                    <Box sx={{ cursor: "pointer" }} onClick={() => authState.useLogout()}>
                        <LogoutIcon color={theme.palette.settingDialog.icon} />
                    </Box>
                </Box>
                <Box>
                    <Typography color={theme.palette.settingDialog.subText}>Ortaya çıkan herhangi bir ipucu tahminlerde kullanılmalıdır.</Typography>
                </Box>
            </Box>
        </Fragment>
    ) : (
        <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={() => authState.useLogin()}>
                <Typography sx={{ fontSize: 26, fontWeight: 700 }}>{translate("login")}</Typography>
                <Box>
                    <LoginIcon color={theme.palette.settingDialog.icon} />
                </Box>
            </Box>
            <Box>
                <Typography color={theme.palette.settingDialog.subText}>Ortaya çıkan herhangi bir ipucu tahminlerde kullanılmalıdır.</Typography>
            </Box>
        </Box>
    );
};

export default SettingLoginBlock;
