import { FC, useMemo } from "react";
import { IValueLabel } from "../../interfaces";
import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";

type IProps = {
    label: string;
    value: any;
    options: IValueLabel<any>[];
    disableClearable?: boolean;
    onChange: (event: any, newValue: any) => void;
};

const AutoCompleteInput: FC<IProps> = (props) => {
    const { label, value, options, disableClearable, onChange } = props;

    return useMemo(
        () => (
            <Autocomplete
                isOptionEqualToValue={(_option, _value) => true}
                value={!!value ? options.find((option: IValueLabel<any>) => option.value === value) : ""}
                options={options}
                fullWidth
                disableClearable={disableClearable}
                onChange={onChange}
                renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} label={label} />}
            />
        ),
        [options, value, label, disableClearable, onChange]
    );
};

AutoCompleteInput.defaultProps = {
    disableClearable: true,
};

export default AutoCompleteInput;
