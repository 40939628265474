import useLocales from "../../hooks/useLocales";
import { LineerProgressBar } from "../../components";
import { Box, Container, Grid } from "@mui/material";
import { generateRandomNumber } from "../../utils/methods";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

type IProps = {
    progress: number;
    setProgress: Dispatch<SetStateAction<number>>;
    setIsCompleteSplashScreen: Dispatch<SetStateAction<boolean>>;
};

const SplashScreen: FC<IProps> = (props) => {
    const { progress, setProgress, setIsCompleteSplashScreen } = props;
    const { translate } = useLocales();
    const [pageHeight, setPageHeight] = useState<number>(0);

    useEffect(() => {
        const root: HTMLElement = document.getElementById("root") as HTMLElement;

        root.style.background = "linear-gradient(170.74deg, #17812D 21.76%, #C5D49A 111.56%)";
    }, []);

    useEffect(() => {
        const ms: number = generateRandomNumber(1500, 2500);
        const stepSize: number = 100 / (ms / 100);

        const timer: NodeJS.Timer = setInterval(() => {
            setProgress((prevProgress: number) => {
                const newValue: number = prevProgress + stepSize;

                return newValue >= 100 ? 100 : newValue;
            });
        }, 100);

        return () => {
            clearInterval(timer);
        };
    }, [setProgress]);

    useEffect(() => {
        if (progress === 100) {
            const timer: NodeJS.Timer = setTimeout(() => {
                setIsCompleteSplashScreen(true);
            }, 400);

            return () => {
                clearInterval(timer);
            };
        }
    }, [progress, setIsCompleteSplashScreen]);

    useEffect(() => {
        const handleResize = () => setPageHeight(window.innerHeight);

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Container
            maxWidth="md"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: `${pageHeight}px`, userSelect: "none" }}>
            <Grid container gap={2}>
                <Grid item xs={12}>
                    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6} md={4}>
                            <img src="./assets/images/splash-daily-logo.png" alt="splash-logo" width="100%" />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6} md={4}>
                            <Box sx={{ border: "2px solid #1B7A2E", borderRadius: "31.41px", p: 0.5 }}>
                                <LineerProgressBar value={progress} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                        <Grid item xs={6} md={4}>
                            <Box sx={{ textAlign: "center", color: "#1B7A2E", fontSize: 16, fontWeight: 600, userSelect: "none" }}>
                                {translate("launching")}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default SplashScreen;
