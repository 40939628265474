import { combineReducers, configureStore } from "@reduxjs/toolkit";

import themeReducer from "../features/theme/ThemeSlice";
import { ApiSlice } from "../features/api/Api";

const rootReducer = combineReducers({
    [ApiSlice.reducerPath]: ApiSlice.reducer,
    theme: themeReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(ApiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
