import { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "../../../../../../hooks";
import { SwitchButton } from "../../../../../../components";
import useLocales from "../../../../../../hooks/useLocales";
import { useAppDispatch } from "../../../../../../app/hooks";
import { changeBlindMode } from "../../../../../../features/theme/ThemeSlice";

const SettingBlindModeBlock = () => {
    const dispatch = useAppDispatch();
    const { translate } = useLocales();
    const { theme, blindMode }: { theme: any; blindMode: boolean } = useTheme();

    return (
        <Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>{translate("blindMode")}</Typography>
                <SwitchButton name="colorBlindMode" checked={blindMode} onChange={() => dispatch(changeBlindMode())} />
            </Box>
            <Box>
                <Typography color={theme.palette.settingDialog.subText}>{translate("blindModeDesc")}</Typography>
            </Box>
        </Fragment>
    );
};

export default SettingBlindModeBlock;
