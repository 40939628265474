import { FC, useMemo } from "react";
import { IValueLabel } from "../../interfaces";
import { FormControl, InputLabel, MenuItem, Select, SxProps } from "@mui/material";

type IProps = {
    label?: string;
    value: any;
    options: IValueLabel<any>[];
    onChange: (event: any, newValue: any) => void;
    sx?: SxProps;
};

const SelectInput: FC<IProps> = (props) => {
    const { label, value, options, onChange, sx } = props;

    return useMemo(
        () => (
            <FormControl fullWidth>
                {label && <InputLabel>{label}</InputLabel>}
                <Select
                    label={label}
                    sx={sx}
                    value={!!value ? options.find((option: IValueLabel<any>) => option.value === value)?.value : ""}
                    onChange={onChange}
                    fullWidth>
                    {options.map((option: IValueLabel<any>) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ),
        [options, value, label, onChange]
    );
};

export default SelectInput;
