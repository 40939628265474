import { BaseQueryApi, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { rawBaseQuery } from "../features/api/Api";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export const responseHandler = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
    const result: MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>> = await rawBaseQuery(args, api, extraOptions);

    //dönüş statü kodlarına göre işlemler

    return result;
};
