import { Box } from "@mui/material";
import { FC, Fragment, useState } from "react";
import { WordRow } from "../../../components";
import { GUESS_LENGTH, LETTER_LENGTH } from "../../../constants/settings";
import { WordleStateLocale, wordleStore } from "../../../features/wordle/WordleStore";
import { GameState } from "../../../utils/enums";
import { useLocales } from "../../../hooks";
import { GuessRow } from "../../../interfaces";

type IProps = {
    guess: string;
    sizeHeight: number;
    sizeWidth: number;
};

const Wordle: FC<IProps> = (props) => {
    const wordleState: WordleStateLocale = wordleStore();
    const { currentLang } = useLocales();
    const { guess: guessProp, sizeHeight, sizeWidth } = props;
    const [blockGapSize] = useState<number>(5);

    let rows: GuessRow[] = [...wordleState[currentLang.value].rows];

    const currentRow: number = rows.length < GUESS_LENGTH ? rows.push({ guess: guessProp } as GuessRow) - 1 : GUESS_LENGTH;

    const numberOfGuessessRemaining: number = GUESS_LENGTH - rows.length;

    rows = rows.concat(Array(numberOfGuessessRemaining).fill({ guess: "" } as GuessRow));

    const blockSizeForWidth: number = (sizeWidth - blockGapSize * (LETTER_LENGTH - 1)) / LETTER_LENGTH;
    const blockSizeForHeight: number = (sizeHeight - blockGapSize * (GUESS_LENGTH - 1)) / GUESS_LENGTH;

    const minSize: number = Math.min(blockSizeForHeight, blockSizeForWidth) + 12;

    return (
        <Fragment>
            <Box
                id="wordle"
                sx={{
                    maxWidth: `${LETTER_LENGTH * minSize}px`,
                    maxHeight: `${GUESS_LENGTH * minSize}px`,
                    overflow: "hidden",
                }}>
                {rows.map((word: GuessRow, index: number) => {
                    const isCurrentRow: boolean =
                        wordleState[currentLang.value].gameState === GameState.WON ? currentRow - 1 === index : currentRow === index;

                    return <WordRow key={index} letters={word.guess} result={word.result} isCurrentRow={isCurrentRow} maxBoxSize={minSize} />;
                })}
            </Box>
        </Fragment>
    );
};

export default Wordle;
