import { create } from "zustand";
import { persist } from "zustand/middleware";
import { computeGuess, setGameStatus, setSpentTime } from "../../utils/methods";
import { GUESS_LENGTH } from "../../constants/settings";
import { GameState, Languge, LetterState } from "../../utils/enums";
import { startOfDay } from "date-fns";
import { GuessRow } from "../../interfaces";

export type WordleStateLocale = {
    [Languge.tr]: WordleState;
    [Languge.en]: WordleState;
    clear: () => void;
    addGuess: (guess: string, language: Languge.tr | Languge.en) => Promise<AddGuessResponse>;
    setGameTime: (newTime: Date, language: Languge.tr | Languge.en) => void;
};

export type WordleState = {
    rows: GuessRow[];
    gameState: GameState;
    gameTime: Date;
};

export type AddGuessResponse = {
    isGameEnded: boolean;
    guessLength: number;
};

const wordleInitial: any = {
    [Languge.tr]: { rows: [] as GuessRow[], gameState: GameState.PLAYING, gameTime: startOfDay(new Date()) } as WordleState,
    [Languge.en]: { rows: [] as GuessRow[], gameState: GameState.PLAYING, gameTime: startOfDay(new Date()) } as WordleState,
};

export const wordleStore = create<WordleStateLocale>()(
    persist(
        (set, get) => ({
            ...wordleInitial,
            addGuess: async (guess: string, language: Languge.tr | Languge.en): Promise<AddGuessResponse> => {
                const result: LetterState[] = await computeGuess(guess, language);

                const rows: GuessRow[] = [
                    ...get()[language].rows,
                    {
                        guess,
                        result,
                    },
                ];

                const isWin: boolean = result.every((value: LetterState) => value === LetterState.MATCH);

                const newGameState: GameState = isWin ? GameState.WON : rows.length === GUESS_LENGTH ? GameState.LOST : GameState.PLAYING;

                const isGameEnded: boolean = newGameState === GameState.LOST || newGameState === GameState.WON;

                if (isGameEnded) {
                    const spentTime = setSpentTime(get()[language].gameTime, language);
                    const gameStatus = setGameStatus(newGameState, language);

                    await spentTime;
                    await gameStatus;
                }

                set(
                    () =>
                        ({
                            ...get(),
                            [language]: {
                                ...get()[language],
                                rows,
                                gameState: newGameState,
                            },
                        } as WordleStateLocale)
                );

                return { isGameEnded, guessLength: rows.length } as AddGuessResponse;
            },
            clear: () => {
                set(() => wordleInitial);
            },
            setGameTime: (newTime: Date, language: Languge.tr | Languge.en) => {
                set(() => ({
                    ...get(),
                    [language]: {
                        ...get()[language],
                        gameTime: new Date(newTime),
                    },
                }));
            },
        }),
        { name: "wordle" }
    )
);
