import { useState } from "react";
import { SettingContext } from "..";

const SettingProvider = (props: any) => {
    const [isOpenSettingDialog, setIsOpenSettingDialog] = useState<boolean>(false);

    return (
        <SettingContext.Provider
            value={{
                isOpen: isOpenSettingDialog,
                setIsOpen: setIsOpenSettingDialog,
            }}>
            {props.children}
        </SettingContext.Provider>
    );
};

export default SettingProvider;
