import CryptoJS from "crypto-js";
import { addSeconds, format, getHours, getMinutes, getSeconds, startOfDay } from "date-fns";
import { store } from "../../app/store";
import { GameState, Languge, LetterState } from "../enums";
import { ApiSlice } from "../../features/api/Api";
import queryString from "query-string";
import i18n from "../../i18n";
import { GetGameNotificationMessageRes } from "../../interfaces";
import { EhhIcon, GeniousIcon, InfluentialIcon, SparkleIcon, VeryGoodIcon, WonderfulIcon } from "../../constants/notificationIcons";
import { IResponse } from "../../interfaces/Types";
import { ErrorStatusCodes } from "../enums/errorStatusCodes";

export const generateRandomNumber = (min: number = 0, max: number = 100): number => Math.floor(Math.random() * (max - min + 1)) + min;

export const computeGuess = async (guess: string, currentLang: Languge): Promise<LetterState[]> => {
    const res: any = await store.dispatch(
        ApiSlice.endpoints.create.initiate({
            resource: `dailywords/check-word?${queryString.stringify({ word: guess, deviceToken: "6c39c61a-f5c7-4354-850b-91401593def9" })}`,
            headers: {
                langCode: currentLang,
                currentDate: createHash(getCurrentDateForHeader),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));

    return res.data.data.match;
};

export const setSpentTime = async (spentTime: Date, currentLang: Languge): Promise<void> => {
    const spentTimeSecond: number = getTotalSeconds(spentTime);

    const res: any = await store.dispatch(
        ApiSlice.endpoints.update.initiate({
            resource: `dailywords/set-spent-time?${queryString.stringify({
                spentTime: spentTimeSecond,
                deviceToken: "6c39c61a-f5c7-4354-850b-91401593def9",
            })}`,
            headers: {
                langCode: currentLang,
                currentDate: createHash(getCurrentDateForHeader),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));
};

export const setGameStatus = async (gameStatus: GameState, currentLang: Languge): Promise<void> => {
    const res: any = await store.dispatch(
        ApiSlice.endpoints.update.initiate({
            resource: `dailywords/set-game-status?${queryString.stringify({
                status: GameState[gameStatus],
                deviceToken: "6c39c61a-f5c7-4354-850b-91401593def9",
            })}`,
            headers: {
                langCode: currentLang,
                currentDate: createHash(getCurrentDateForHeader),
            },
        })
    );

    if (!res || !res?.data) throw new Error(i18n.t("server_error"));

    if (res.data?.errorMessage) throw new Error(JSON.stringify(res.data));
};

export const getTotalSeconds = (date: Date): number => {
    const hours: number = getHours(date);
    const minutes: number = getMinutes(date);
    const seconds: number = getSeconds(date);

    return hours * 3600 + minutes * 60 + seconds;
};

export const secondToDate = (second: number): Date => {
    const startDate: Date = startOfDay(new Date());

    const result: Date = addSeconds(startDate, second);

    return result;
};

export const floatToFixedFloat = (number: number, fixedCount: number = 2) => parseFloat(number.toFixed(fixedCount));

export const getCurrentDateForHeader = `${format(new Date(), "yyyy-MM-dd")}${process.env.REACT_APP_SALT_KEY}`;

export const createHash = (input: string, saltKey?: string) =>
    saltKey
        ? CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(input + saltKey)).toString(CryptoJS.enc.Hex)
        : CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(input)).toString(CryptoJS.enc.Hex);

export const getGameEndNotification = (guessLength: number): GetGameNotificationMessageRes => {
    const guessMessages = [
        "",
        "one_guess_game_ended",
        "two_guess_game_ended",
        "three_guess_game_ended",
        "four_guess_game_ended",
        "five_guess_game_ended",
        "six_guess_game_ended",
    ];

    const guessIcons = ["", GeniousIcon, WonderfulIcon, InfluentialIcon, SparkleIcon, VeryGoodIcon, EhhIcon];

    return { message: i18n.t(guessMessages[guessLength]), icon: guessIcons[guessLength] } as GetGameNotificationMessageRes;
};

export const getErrorNotification = (error: any): GetGameNotificationMessageRes => {
    let errorObject: any;

    try {
        errorObject = JSON.parse(error.message);

        if (isIResponse(errorObject)) {
            switch (errorObject.bundleStatusCode) {
                case ErrorStatusCodes.USER_NOT_FOUND:
                    return { message: i18n.t("user_not_found") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.WORD_NOT_FOUND:
                    return { message: i18n.t("word_not_found"), icon: "❌" } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.INVALID:
                    return { message: i18n.t("invalid_request") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.SYSTEM_ERROR:
                    return { message: i18n.t("server_error") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.CANNOT_PLAY:
                    return { message: i18n.t("user_cannot_play") } as GetGameNotificationMessageRes;
                case ErrorStatusCodes.USER_SCORE_NOT_FOUND:
                    return { message: i18n.t("user_score_not_found") } as GetGameNotificationMessageRes;
                default:
                    return { message: errorObject.errorMessage } as GetGameNotificationMessageRes;
            }
        } else if (typeof errorObject === "string") {
            return { message: errorObject } as GetGameNotificationMessageRes;
        }
    } catch {
        return { message: error } as GetGameNotificationMessageRes;
    }

    return { message: error } as GetGameNotificationMessageRes;
};

export const isIResponse = (obj: any): obj is IResponse => {
    return typeof obj.bundleStatusCode === "number" && obj.hasOwnProperty("errorMessage") && obj.hasOwnProperty("data");
};
