import { FC } from "react";

type IProps = {
    width?: number;
    height?: number;
    color?: string;
};

export const PhilipsIcon: FC<IProps> = ({ color = "#0B5ED7", width = 50, height = 10 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.9618 0.200519H14.2755V4.01582H11.4792V0.200519H8.79293V9.79948H11.4792V6.02374H14.2755V9.79948H16.9618V0.200519ZM26.3062 7.87069V0.200519H23.62V9.79948H28.4773L28.8812 7.87069H26.3062ZM21.634 0.200519H18.9478V9.79948H21.634V0.200519ZM32.5242 0.200519H29.8392V9.79948H32.5242V0.200519ZM45.768 2.65039C45.768 2.12795 46.247 1.88651 47.0186 1.88651C47.8652 1.88651 48.7857 2.08839 49.411 2.37075L49.1909 0.280998C48.4905 0.120038 47.8652 0 46.7259 0C44.5549 0 43.083 0.934389 43.083 2.89183C43.083 6.26518 47.2787 5.58314 47.2787 7.14909C47.2787 7.75065 46.8723 8.07121 45.8793 8.07121C44.9976 8.07121 43.6345 7.75065 42.8253 7.30869L43.1205 9.51848C43.9296 9.8404 45.0326 10 45.9168 10C48.1604 10 50 9.19656 50 6.78625C50 3.57523 45.768 4.17678 45.768 2.65039ZM38.1906 7.51057C40.6906 7.51057 42.2738 6.10422 42.2738 3.65435C42.2738 0.964398 40.5818 0 37.8217 0C36.755 0 35.4669 0.0804796 34.5477 0.200519V9.79948H37.2339V7.51057H38.1906ZM39.6251 3.69527C39.6251 4.9393 39.0736 5.78366 37.6741 5.78366H37.2339V1.81694C37.3903 1.80739 37.5366 1.80739 37.6741 1.80739C38.9985 1.80739 39.6251 2.48943 39.6251 3.69527ZM3.64298 7.51057C6.14417 7.51057 7.72617 6.10422 7.72617 3.65435C7.72617 0.964398 6.03412 0 3.27531 0C2.2073 0 0.920437 0.0804796 0 0.200519V9.79948H2.68628V7.51057H3.64298ZM5.07866 3.69527C5.07866 4.9393 4.5259 5.78366 3.12774 5.78366H2.68628V1.81694C2.8426 1.80739 2.99017 1.80739 3.12774 1.80739C4.45212 1.80739 5.07866 2.48943 5.07866 3.69527Z"
                fill={color}
            />
        </svg>
    );
};
