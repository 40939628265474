import { ThemeMode } from "../../utils/enums";
import { createSlice } from "@reduxjs/toolkit";

export interface ThemeState {
    mode: ThemeMode;
    blindMode: boolean;
}

const initialState: ThemeState = { mode: ThemeMode.light, blindMode: false };

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        changeTheme(state) {
            state.mode = state.mode === ThemeMode.dark ? ThemeMode.light : ThemeMode.dark;
            localStorage.setItem("theme-mode", state.mode);
        },
        changeBlindMode(state) {
            state.blindMode = !state.blindMode;
            localStorage.setItem("theme-blind-mode", state.blindMode.toString());
        },
    },
});

export const { changeTheme, changeBlindMode } = themeSlice.actions;

export const selectThemeMode = (): string => {
    const themeMode: string | null = localStorage.getItem("theme-mode");

    if (!themeMode) localStorage.setItem("theme-mode", initialState.mode);

    return localStorage.getItem("theme-mode")!;
};

export const selectThemeBlindMode = (): boolean => {
    const themeBlindMode: string | null = localStorage.getItem("theme-blind-mode");

    if (themeBlindMode === null) localStorage.setItem("theme-blind-mode", initialState.blindMode.toString());

    return localStorage.getItem("theme-blind-mode") === "true";
};

export const tokens = (mode: any) => ({
    ...(mode === "dark"
        ? {
              grey: {
                  100: "#D9D9D9",
                  200: "#7A7A7A",
                  250: "#86919C",
                  300: "#B3BAC1",
                  350: "#4A4E52",
                  400: "#9C9C9C",
                  500: "#adb5bd",
                  600: "#505050",
                  700: "#495057",
                  800: "#181E25",
                  850: "#121A21",
                  900: "#232C35",
                  950: "#0A0E12",
                  1000: "rgba(153, 153, 153, 0.361)",
              },
              primary: {
                  100: "#000000",
                  200: "#a1a4ab",
                  300: "#727681",
                  400: "#34244c",
                  500: "#1a1524",
                  600: "#101624",
                  700: "#0c101b",
                  800: "#0A0E12",
                  900: "#ffffff",
              },
              green: {
                  100: "#dbf5ee",
                  200: "#b7ebde",
                  300: "#94e2cd",
                  400: "#70d8bd",
                  500: "#08b4e9",
                  600: "#31AC37",
                  700: "#2e7c67",
                  800: "#1e5245",
                  900: "#0f2922",
              },
              red: {
                  100: "#f8dcdb",
                  200: "#f1b9b7",
                  300: "#e99592",
                  400: "#e2726e",
                  500: "#db4f4a",
                  600: "#af3f3b",
                  700: "#832f2c",
                  800: "#58201e",
                  900: "#2c100f",
              },
              blue: {
                  100: "#e1e2fe",
                  200: "#c3c6fd",
                  300: "#a4a9fc",
                  400: "#868dfb",
                  450: "#599FAC",
                  500: "#6870fa",
                  600: "#535ac8",
                  700: "#0B5ED7",
                  800: "#2a2d64",
                  900: "#151632",
              },
              pink: {
                  200: "#F5A2AE",
              },
              purple: {
                  default: "#1B1625",
                  100: "#7388FC",
              },
              yellow: {
                  500: "#EDAF36",
              },
          }
        : {
              grey: {
                  100: "#eeeeee",
                  150: "#D9D9D9",
                  200: "#E4E4E4",
                  250: "#86919C",
                  300: "#B3BAC1",
                  350: "#4A4E52",
                  400: "#BAC4C9",
                  500: "#adb5bd",
                  600: "#ced4da",
                  700: "#dee2e6",
                  800: "#7A7A7A",
                  900: "#C7C7C7",
                  950: "#0A0E12",
                  1000: "rgba(153, 153, 153, 0.361)",
              },
              primary: {
                  100: "#ffffff",
                  200: "#f4f5f6",
                  300: "#0c101b",
                  400: "#fff",
                  500: "#f7f7f7",
                  600: "#1F2A40",
                  700: "#727681",
                  800: "#a1a4ab",
                  900: "#000000",
              },
              green: {
                  100: "#0f2922",
                  200: "#1e5245",
                  300: "#2e7c67",
                  400: "#3da58a",
                  500: "#853ef8",
                  600: "#31AC37",
                  700: "#94e2cd",
                  800: "#b7ebde",
                  900: "#dbf5ee",
              },
              red: {
                  100: "#2c100f",
                  200: "#58201e",
                  300: "#832f2c",
                  400: "#af3f3b",
                  500: "#db4f4a",
                  600: "#e2726e",
                  700: "#e99592",
                  800: "#f1b9b7",
                  900: "#f8dcdb",
              },
              blue: {
                  100: "#151632",
                  200: "#2a2d64",
                  300: "#3e4396",
                  400: "#535ac8",
                  450: "#599FAC",
                  500: "#6870fa",
                  600: "#868dfb",
                  700: "#0B5ED7",
                  800: "#c3c6fd",
                  900: "#e1e2fe",
              },
              pink: {
                  200: "#F5A2AE",
              },
              purple: {
                  default: "#853EF8",
                  800: "#3748A1",
              },
              yellow: {
                  500: "#EDAF36",
              },
          }),
});

export const themeSettings = (mode: any) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode,
            ...(mode === "dark"
                ? {
                      // palette values for dark mode
                      text: {
                          primary: colors.primary[900],
                      },
                      primary: {
                          main: colors.primary[500],
                      },
                      secondary: {
                          main: colors.green[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: colors.primary[500],
                      },
                      adwert: {
                          brand: colors.blue[700],
                          slogan: colors.primary[900],
                          background: colors.grey[800],
                      },
                      wordleTimer: {
                          text: colors.grey[400],
                          time: colors.primary[900],
                      },
                      sponsor: {
                          brand: colors.blue[700],
                          text: colors.primary[900],
                      },
                      keyboard: {
                          keyBackground: colors.grey[900],
                          text: colors.grey[200],
                      },
                      settingDialog: {
                          background: colors.grey[850],
                          xsBackground: colors.primary[100],
                          icon: colors.primary[900],
                          subText: colors.grey[250],
                          sliderDot: colors.grey[900],
                          prevNext: colors.grey[300],
                      },
                      statisticDialog: {
                          background: colors.grey[800],
                          icon: colors.primary[900],
                          subText: colors.grey[250],
                          sliderDot: colors.grey[900],
                          prevNext: colors.grey[300],
                          tabTitleBackground: colors.grey[900],
                          dataBackground: colors.grey[900],
                          dataTitle: colors.grey[300],
                          predictionUserBackground: colors.grey[900],
                          gameAdwertBackground: colors.grey[900],
                          arrowColor: colors.grey[300],
                          premiumIcon: colors.primary[900],
                          notAuthenticateTitle: colors.purple[100],
                          leaderboardTitle: colors.primary[900],
                          leaderboardItemBorder: colors.grey[350],
                          leaderboardItemBackground: colors.grey[900],
                          leaderboardItemCurrUserBackground: colors.green[600],
                          leaderboardItemUsername: colors.primary[900],
                          leaderboardAfterBackground: "linear-gradient(180deg, rgba(24, 30, 37, 0.00) 0%, #181E25 100%)",
                          loadingBackground: "linear-gradient(90deg, #232C35 0%, rgba(179, 186, 193, 0.12) 100%);",
                      },
                      wordle: {
                          missBackground: colors.grey[800],
                          blindMissBackground: colors.grey[800],
                          presentBackground: colors.yellow[500],
                          blindPresentBackground: colors.pink[200],
                          matchBackground: colors.green[600],
                          blindMatchBackground: colors.blue[450],
                          characterBorder: colors.grey[900],
                          currentRowTextColor: colors.primary[900],
                      },
                  }
                : {
                      // palette values for light mode
                      text: {
                          primary: colors.primary[900],
                      },
                      primary: {
                          main: colors.primary[100],
                      },
                      secondary: {
                          main: colors.green[500],
                      },
                      neutral: {
                          dark: colors.grey[700],
                          main: colors.grey[500],
                          light: colors.grey[100],
                      },
                      background: {
                          default: "#fcfcfc",
                      },
                      adwert: {
                          brand: colors.blue[700],
                          slogan: colors.primary[900],
                          background: colors.grey[100],
                      },
                      wordleTimer: {
                          text: colors.grey[300],
                          time: colors.primary[900],
                      },
                      sponsor: {
                          brand: colors.blue[700],
                          text: colors.primary[900],
                      },
                      keyboard: {
                          keyBackground: colors.grey[200],
                          text: colors.primary[900],
                      },
                      settingDialog: {
                          background: colors.primary[200],
                          xsBackground: colors.primary[200],
                          icon: colors.primary[900],
                          subText: colors.grey[250],
                          sliderDot: colors.grey[150],
                          prevNext: colors.primary[900],
                      },
                      statisticDialog: {
                          background: colors.primary[200],
                          icon: colors.primary[900],
                          subText: colors.grey[250],
                          sliderDot: colors.grey[150],
                          prevNext: colors.primary[900],
                          tabTitleBackground: colors.primary[100],
                          dataBackground: colors.primary[100],
                          dataTitle: colors.grey[350],
                          predictionUserBackground: colors.grey[400],
                          gameAdwertBackground: colors.primary[100],
                          arrowColor: colors.primary[800],
                          premiumIcon: colors.primary[900],
                          notAuthenticateTitle: colors.purple[800],
                          leaderboardTitle: colors.grey[950],
                          leaderboardItemBorder: colors.grey[300],
                          leaderboardItemBackground: colors.primary[100],
                          leaderboardItemCurrUserBackground: colors.green[600],
                          leaderboardItemUsername: colors.grey[950],
                          leaderboardAfterBackground: "linear-gradient(180deg, rgba(244, 245, 246, 0) 0%, #f4f5f6 100%)",
                          loadingBackground: "linear-gradient(90deg, #b3bac1 0%, rgba(179, 186, 193, 0.12) 100%);",
                      },
                      wordle: {
                          missBackground: colors.grey[300],
                          blindMissBackground: colors.grey[300],
                          presentBackground: colors.yellow[500],
                          blindPresentBackground: colors.pink[200],
                          matchBackground: colors.green[600],
                          blindMatchBackground: colors.blue[450],
                          characterBorder: colors.grey[300],
                          currentRowTextColor: colors.primary[900],
                      },
                  }),
        },
        typography: {
            fontFamily: ["Inter", "sans-serif"].join(","),
            fontSize: 12,
            colors: colors.purple["default"],
            h1: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: 14,
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 550,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
    };
};

export default themeSlice.reducer;
