import { Theme, createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useAppSelector } from "../app/hooks";
import { selectThemeBlindMode, selectThemeMode, themeSettings, tokens } from "../features/theme/ThemeSlice";

const useThemeMode = () => {
    const mode: string = useAppSelector(selectThemeMode);
    const blindMode: boolean = useAppSelector(selectThemeBlindMode);
    const theme: Theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    const colors: any = useMemo(() => tokens(theme.palette.mode), [theme]);

    return { theme, mode, blindMode, colors };
};

export default useThemeMode;
