import { Box, Hidden } from "@mui/material";
import "../../scss/components/header.scss";
import useTheme from "../../hooks/useTheme";
import { useContext, useState } from "react";
import { SettingContext } from "../../states";
import { SettingContextType } from "../../states/contexts/SettingContext";
import { HomeIcon, ProfileIcon, StatisticsIcon } from "../../constants/icons";
import { StatisticDialog, WordleTimer } from "../../screens/Wordle/common";

const Header = () => {
    const { mode } = useTheme();
    const settingContext: SettingContextType = useContext(SettingContext);
    const [isOpenStatisticDialog, setIsOpenStatisticDialog] = useState<boolean>(false);

    return (
        <Box id="header" sx={{ justifyContent: { xs: "end", sm: "space-between" } }}>
            <Box className="logo">
                <img src={`./assets/images/wordle-logo-${mode}.png`} alt="wordle-logo" height={22} />
            </Box>

            <Box className="menu-icons" sx={{ justifyContent: "end" }}>
                <Box id="header-timer">
                    <Hidden smUp>
                        <WordleTimer isHeader />
                    </Hidden>
                </Box>

                <Box id="home-icon">
                    <HomeIcon />
                </Box>

                <Box
                    id="statistic-icon"
                    onClick={() => {
                        setIsOpenStatisticDialog(true);
                    }}>
                    <StatisticsIcon />
                </Box>
                <Box
                    id="profile-icon"
                    onClick={() => {
                        settingContext.setIsOpen(true);
                    }}>
                    <ProfileIcon />
                </Box>
            </Box>

            <StatisticDialog isOpen={isOpenStatisticDialog} setIsOpen={setIsOpenStatisticDialog} />
        </Box>
    );
};

export default Header;
