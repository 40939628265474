import { GameSummary, PointTable } from "./common";
import { IValueLabel } from "../../../../../interfaces";
import { GameState, StatisticTabs } from "../../../../../utils/enums";
import { useLocales, useTheme } from "../../../../../hooks";
import { TransitionProps } from "@mui/material/transitions";
import { CloseIcon, ShareIcon } from "../../../../../constants/icons";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Slide, Tab, Tabs, Typography } from "@mui/material";
import { Dispatch, FC, ReactElement, Ref, SetStateAction, SyntheticEvent, forwardRef, useCallback, useEffect, useState } from "react";
import StatisticAdwert from "./common/StatisticAdwert";
import { WordleStateLocale, wordleStore } from "../../../../../features/wordle/WordleStore";
import { AuthState, authStore } from "../../../../../features/authenticate/AuthStore";

type IProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const StatisticDialog: FC<IProps> = (props) => {
    const { isOpen, setIsOpen } = props;
    const { theme }: any = useTheme();
    const { translate, currentLang } = useLocales();
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const wordleState: WordleStateLocale = wordleStore();
    const authState: AuthState = authStore();

    const closeHandler = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const tabChangeHandler = useCallback((_event: SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    }, []);

    useEffect(() => {
        let timeOut: NodeJS.Timeout;

        if (wordleState[currentLang.value].gameState !== GameState.PLAYING) {
            timeOut = setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        }

        return () => {
            timeOut && clearTimeout(timeOut);
        };
    }, [wordleState[currentLang.value].gameState]);

    return (
        <Box id="statistic-dialog-box">
            <Dialog
                sx={{
                    "& .MuiDialog-paper": {
                        background: theme.palette.statisticDialog.background,
                    },
                }}
                open={isOpen}
                onClose={closeHandler}
                TransitionComponent={Transition}
                maxWidth="sm"
                fullWidth
                id="statistic-dialog">
                <DialogTitle>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography className="statistic-dialog-title">{translate("statistic")}</Typography>

                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, cursor: "pointer" }}>
                            <Typography className="statistic-dialog-share">{translate("share")}</Typography>{" "}
                            <Box sx={{ display: "flex" }}>
                                <ShareIcon color={theme.palette.statisticDialog.icon} />
                            </Box>
                        </Box>
                    </Box>

                    <Box className="statistic-dialog-close-box" sx={{ background: theme.palette.statisticDialog.background }}>
                        <IconButton aria-label="close" onClick={closeHandler}>
                            <CloseIcon color={theme.palette.statisticDialog.icon} />
                        </IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent id="statistic-dialog-content">
                    <Tabs
                        sx={{ background: theme.palette.statisticDialog.tabTitleBackground }}
                        value={selectedTab}
                        onChange={tabChangeHandler}
                        variant="scrollable"
                        scrollButtons={false}
                        id="statistic-tab">
                        {statisticTabs.map((tab: IValueLabel<StatisticTabs>, index: number) => (
                            <Tab className="statistic-tab-item" key={index} label={translate(tab.label)} />
                        ))}
                    </Tabs>

                    <Box>
                        {selectedTab === StatisticTabs.GameSummary && <GameSummary />}
                        {selectedTab === StatisticTabs.PointTable && <PointTable />}
                    </Box>

                    {!authState.isPremium && (
                        <Box>
                            <StatisticAdwert />
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

const statisticTabs: IValueLabel<StatisticTabs>[] = [
    {
        label: "gameSummary",
        value: StatisticTabs.GameSummary,
    },
    {
        label: "pointTable",
        value: StatisticTabs.GameSummary,
    },
];

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default StatisticDialog;
