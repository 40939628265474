import useThemeMode from "../../../hooks/useTheme";
import { ArrowIcon } from "../../../constants/icons";
import { Box, Divider, Typography } from "@mui/material";
import { PhilipsIcon } from "../../../constants/brandIcons";
import { useEffect } from "react";

declare global {
    interface Window {
        googletag: any;
    }
}

const Adwert = () => {
    const { theme }: any = useThemeMode();

    useEffect(() => {
        window?.googletag?.cmd?.push(function () {
            window.googletag.display("div-gpt-ad-1715686355921-0");
        });
    }, []);

    return (
        <Box id="adwert" sx={{ background: theme.palette.adwert.background }}>
            {/*<PhilipsIcon width={160} height={13} />

            <Divider orientation="vertical" flexItem sx={{ background: "#B3B3B3" }} />

            <Typography id="adwert-slogan" sx={{ color: theme.palette.adwert.slogan, fontSize: { xs: "0.7em", sm: "1em" } }}>
                Her seferinde lezzetli sonuçlar sunan yeni Airfryer 7000 XXL ile yemek yapmak hiç bu kadar kolay olmamıştı.
            </Typography>*/}

            <Box id="div-gpt-ad-1715686355921-0" />

            <Box>
                <ArrowIcon width={20} height={12} />
            </Box>
        </Box>
    );
};

export default Adwert;
