import { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import useLocales from "../../../../../../hooks/useLocales";
import { BundleSlider } from "../../../../../../components";
import useTheme from "../../../../../../hooks/useTheme";

const SettingHowToPlayBlock = () => {
    const { translate } = useLocales();
    const { mode } = useTheme();

    return (
        <Fragment>
            <Box>
                <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{translate("howToPlay")}</Typography>
            </Box>
            <Box>
                <BundleSlider>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-1.png`} alt="" width="100%" />
                    </Box>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-2.png`} alt="" width="100%" />
                    </Box>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-3.png`} alt="" width="100%" />
                    </Box>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-4.png`} alt="" width="100%" />
                    </Box>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-5.png`} alt="" width="100%" />
                    </Box>
                    <Box>
                        <img src={`/assets/images/how-to-play-${mode}-6.png`} alt="" width="100%" />
                    </Box>
                </BundleSlider>
            </Box>
        </Fragment>
    );
};

export default SettingHowToPlayBlock;
